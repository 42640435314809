import * as React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import {
  cards,
  navLinks
} from './index.module.css'

const BlogPage = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
      <Layout pageTitle="My Blog Posts">
          <Container maxWidth="lg">
            <Paper elevation={3}>
              <Box p={isMobile ? 2 : 10}>
                <Typography variant="h4" paragraph="true" color="primary">Blog index</Typography>
                <Box p={2}></Box>
                <Grid container spacing={4}>
                  {
                    data.allMdx.nodes.map(node => (
                      <Grid container item sm={12} md={6} justify="center" alignItems="center" justifyContent="center">
                        <Card className={cards}>
                          <Link to={`/blog/${node.slug}`} className={navLinks}>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                alt="Simple HTTP Server with Go"
                                height="140"
                                image={node.frontmatter.hero_image.publicURL}
                                title="Simple HTTP Server with Go"
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                  {node.frontmatter.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" >
                                  {node.frontmatter.description}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                    ))
                  }
                </Grid>
              </Box>
            </Paper>
          </Container>
      </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          hero_image {
            publicURL
          }
        }
        id
       slug
      }
    }
  }
`

export default BlogPage
